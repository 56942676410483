@import "../scss/globals.scss";

.photoAnimation {
  height: 600px;
  width: 100%;
  display: flex;
  padding-top: 15px;
  flex-direction: column;
  align-content: center;

  @media (min-width: 900px) and (max-width: 1440px) {
    height: 500px;
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    height: 550px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 679px) {
    .color {
      display: none;
    }
    .row {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &.item4, &.item5 {
      display: none;
    }
  }

  .photoItem {
    img {
      width: 200px;
      height: 200px;
      @include customize(theme) {
        border: 1px solid getValue(color-c2);
      }
      margin: 15px;
      object-fit: cover;
      transition: all 1.5s ease-in-out;

      &.fill {
        object-fit: fill;
      }
      &.noMargin {
        margin: 0;
        &.row1 {
          transform: translate(0px, 2px);
        }
        &.row2 {
          transform: translate(0px, -2px);
        }
        border: none;
      }
    }

    .color {
      &.c4, &.c2 {
        &.active {
          transform: translate(0px, 236px);
        }
      }

      &.c1, &.c3, &.c5 {
        &.active {
          transform: translate(0px, -236px);
        }
      }

      width: 202px;
      height: 202px;
      border: none;
      position: absolute;
      margin-left: -217px;
      transition: all 1.5s ease-in-out;

      &.hidden {
        visibility: hidden;
        opacity: 0;
        transition: all 1.5s ease-in-out;
      }
    }

    @media (min-width: 680px) and (max-width: 926px) {
      &.item4, &.item5 {
        display: none;
      }
    }

    @media (min-width: 860px) and (max-width: 1080px) {
      &.item5 {
        display: none;
      }
    }

    @media (min-width: 1080px) and (max-width: 1200px) {
      img {
        width: 180px;
        height: 180px;

        &.color {
          width: 182px;
          height: 182px;
          margin-left: -196px;
        }

        &.color {
          &.c4, &.c2 {
            &.active {
              transform: translate(0px, 216px);
            }

          }

          &.c1, &.c3, &.c5 {
            &.active {
              transform: translate(0px, -216px);
            }
          }
        }
      }
    }
    @media (min-width: 1480px) {
      img {
        width: 230px;
        height: 230px;

        &.color {
          width: 232px;
          height: 232px;
          margin-left: -247px;
        }

        &.color {
          &.c4, &.c2 {
            &.active {
              transform: translate(0px, 266px);
            }

          }

          &.c1, &.c3, &.c5 {
            &.active {
              transform: translate(0px, -266px);
            }
          }
        }
      }
    }
    @media (min-width: 1680px) {
      img {
        width: 250px;
        height: 250px;

        &.color {
          width: 252px;
          height: 252px;
          margin-left: -267px;
        }

        &.color {
          &.c4, &.c2 {
            &.active {
              transform: translate(0px, 286px);
            }

          }

          &.c1, &.c3, &.c5 {
            &.active {
              transform: translate(0px, -286px);
            }
          }
        }
      }
    }
  }
}
$maxPhoneWidth: 836px;

$themes: (
  default: (
    backgroundColor1: #feffff,
    backgroundColor2: #feffff,
    swiperBgColor: lightyellow,
    documentsRow1: #bdfdfc,
    documentsRow2: #e5fdfd,
    color-c0: #000,
    color-c1: #48A0DC,
    color-c2: lightblue,
    color-c3: white,
    color-c4: #f44336,
    color-c5: darkblue,
    color-c6: blue,
    color-c7: navy,
    color-c8: lawngreen,
    color-c9: #eeeb01,
    color-c10: deeppink,
    color-c11: purple,
    color-c12: orange,
    color-c13: cyan,
    color-c14: #fdd3fb,
    color-c15: #fda89e,
    color-c16: #bbdbfe,
    color-c17: #eee076,
    color-c18: #8de8a8,
    color-c19: #d19aed,
    color-c20: #c8fed8,
    color-c21: #f9f9f9,
    color-c22: #dfdfdf,
    color-c23: gray,
    color-c24: #effdfd,
    color-c25: #dafcfc,
    color-c26: #d1fcfc,
    color-c27: #bdf6ce,
    color-c28: #f4c2fc,
    color-c29: #f8d17a,
  ),
  contrast: (
    backgroundColor1: #000,
    backgroundColor2: #000,
    swiperBgColor: #000,
    documentsRow1: #000,
    documentsRow2: #111,
    color-c0: #ff0,
    color-c1: #ff6200,
    color-c2: #000,
    color-c3: white,
    color-c4: #f44336,
    color-c5: #ff6200,
    color-c6: blue,
    color-c7: navy,
    color-c8: lawngreen,
    color-c9: #eeeb01,
    color-c10: deeppink,
    color-c11: purple,
    color-c12: orange,
    color-c13: cyan,
    color-c14: #fdd3fb,
    color-c15: #fda89e,
    color-c16: #bbdbfe,
    color-c17: #eee076,
    color-c18: #8de8a8,
    color-c19: #d19aed,
    color-c20: #c8fed8,
    color-c21: #f9f9f9,
    color-c22: #dfdfdf,
    color-c23: gray,
    color-c24: #effdfd,
    color-c25: #dafcfc,
    color-c26: #ff6200,
    color-c27: #bdf6ce,
    color-c28: #f4c2fc,
    color-c29: #f8d17a,
  ),
);

// @import "../scss/globals.scss";
/*
@include customize(font) {
  height: getValue(footerHeight);
}
@include customize(font) {
  font-weight: getValue(fontSize18);
}
@include customize(font) {
  font-size: getValue(fontSize18);
}
@include customize(theme) {
  color: getValue(color-c0);
}
@include customize(theme) {
  background-color: getValue(color-c0);
}
*/
$sizes: (
  default: (
    fontSize: 16px,
    fontSize18: 18px,
    fontSize20: 20px,
    fontSize22: 22px,
    fontSize26: 26px,
    fontSize28: 28px,
    fontSizeBigger: 30px,
    headerHeigth: 80px,
    footerHeight: 80px,
    headerHeigthMT: 80px,
    fontAuthor: 13px,
    h1: 26px,
    h2: 22px,
    h3: 18px
  ),
  bigger: (
    fontSize: 20px,
    fontSize18: 22px,
    fontSize20: 24px,
    fontSize22: 26px,
    fontSize26: 30px,
    fontSize28: 32px,
    fontSizeBigger: 26px,
    headerHeigth: 78px,
    footerHeight: 100px,
    headerHeigthMT: 58px,
    fontAuthor: 13px,
    h1: 30px,
    h2: 28px,
    h3: 24px
  )
);

@mixin customize($arg: theme) {
  $array: $themes;
  $prefix: theme;

  @if ($arg == theme) {
    $array: $themes;
    $prefix: theme;
  } @else if ($arg == font) {
    $array: $sizes;
    $prefix: font;
  }


  @each $theme, $map in $array {
    .#{$prefix}-#{$theme} & {
      $array-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($array, $theme), '#{$key}');
        $array-map: map-merge($array-map, ($key: $value)) !global;
      }

      @content;
      $array-map: null !global;
    }
  }
}

@function getValue($key) {
  @return map-get($array-map, $key);
}

@mixin enlarge($val) {
  -webkit-transform: scale($val);
  -ms-transform: scale($val);
  -moz-transform: scale($val);
}

@mixin noSelect() {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.no-top-padding {
  padding-top: 0 !important;
}

no-top-margin {
  margin-top: 0 !important;
}
.addActivity {
  .head {
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
  }
  font-size: 20px;

  button {
    font-size: 20px;
    padding: 10px;
    margin: 10px 10px 10px 0;
  }
  input {
    font-size: 16px;
  }
  .title {
    padding-right: 15px;
  }
}
@import "../scss/globals.scss";

.editGroups {
  .photo {
    width: 300px;
  }
  .group {
    border: solid 1px red;
    width: 450px;
    padding: 10px;
  }
  .groupHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .icons {
    padding: 20px;
  }
  .changeImg {
    width: 50px;
    cursor: pointer;
    &:hover {
      border: 2px red solid;
      @include enlarge(1.4);
    }
  }
}
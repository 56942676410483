@import "../scss/globals.scss";

.left {
  display: inline-block !important;
  float: left;
}

.flexRight {
  height: 100%;
  display: flex;
  margin-left: auto;
}

.show-mobile-only {
  @media (min-width: 900px) {
    display: none !important;
  }
}
.hide-mobile {
  @media (max-width: 900px) {
    display: none !important;
  }
}
.hide-small-mobile {
  @media (max-width: 600px) {
    display: none !important;
  }
}
.hide-tablet {
  @media (min-width: 901px) and (max-width: 1120px) {
    display: none !important;
  }
}

.theme-contrast {
  .swiper-pagination-bullet {
    opacity: unset;
    background-color: #ff0;

    &.swiper-pagination-bullet-active {
      background-color: #ff6200;
    }
  }
  .logo {
    color: #ff0 !important;
    &:hover {
      background-color: #000 !important;
      border: 3px solid #ff6200 !important;
    }
  }
  .navbar {
    a {
      background-color: #000 !important;
      color: #ff0 !important;
    }
    a:hover {
      background-color: #000 !important;
      border: 3px solid #ff6200 !important;
    }
  }
  .articles {
    background-color: #000 !important;
  }
  .document {
    font-size: 20px;
    border: 2px solid #ff6200 !important;
    a {
      color: #ff6200 !important;
    }
  }
  .bip:hover {
    border: 2px solid #ff6200 !important;
  }
  .fontChanger {
    .contrast {
      display: block !important;
    }
    .normal {
      display: none !important;
    }
  }
  .icon_contrast {
    fill: #fff !important;
  }
  .footer-author {
    background-color: #000 !important;
    color: #ff0 !important;
  }
  .one-news {
    background-color: #000 !important;
  }
  .swiper-container {
    border: 2px solid #ff6200 !important;
  }
  .header {
    height: 86px !important;
  }
  .mobile-dropdown-content {
    padding-top: 88px !important;
    background-color: #000 !important;
    a {
      background-color: #000 !important;
      &:hover {
        border: 2px solid #ff6200 !important;
      }
    }
  }
}
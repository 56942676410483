@import "../scss/globals.scss";

.articleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include customize(theme) {
    border-top: 1px dashed getValue(color-c23);
  }
  padding: 30px;
  @media (max-width: 800px) {
    padding-left: 0;
    padding-right: 0;
  }
  margin: 10px;

  .articleTitle {
    font-weight: bold;
    font-family: "Times", Serif;
    display: block;
    @media (max-width: 800px) {
      text-align: center;
    }
  }

  .moreLink {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      font-weight: bold;
      @include customize(theme) {
        color: getValue(color-c5);
      }
    }
    a:hover {
      @include enlarge(1.3);
    }
  }
  &:first-of-type {
    border: none;
  }
}
@import "../scss/globals.scss";

.header {
  z-index: 1;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  @include customize(theme) {
    background-color: getValue(backgroundColor2);
    color: getValue(color-c0);
  }
  @include customize(font) {
    height: getValue(headerHeigth);
  }
  @include customize(font) {
    font-size: getValue(fontSize20);
  }
  @include customize(theme) {
    border-bottom: 1px solid getValue(color-c0);
  }

  .logo {
    text-decoration: none;
    padding: 15px;
    @include noSelect();

    img {
      width: 45px;
      height: 45px;
      padding-right: 10px;

      @media (max-width: 440px) {
        display: none;
      }
    }

    @include customize(font) {
      font-size: getValue(fontSizeBigger);
    }
    @include customize(theme) {
      color: getValue(color-c0);
    }
  }
}
.sticky {
  position: fixed;
  z-index: 99999;
  top: 0;
  width: 100%
}
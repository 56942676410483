@import "../scss/globals.scss";

.navbar {
  display: flex;
  overflow: hidden;
  height: 100%;


  #navMainPage:hover {
    @include customize(theme) {
      background-color: getValue(color-c19);
    }
  }

  #navUs:hover {
    @include customize(theme) {
      background-color: getValue(color-c18);
    }
  }

  #navActivity:hover {
    @include customize(theme) {
      background-color: getValue(color-c17);
    }
  }

  #navParents:hover {
    @include customize(theme) {
      background-color: getValue(color-c16);
    }
  }

  #navArch:hover {
    @include customize(theme) {
      background-color: getValue(color-c15);
    }
  }

  #navContact:hover {
    @include customize(theme) {
      background-color: getValue(color-c14);
    }
  }

  > a, .dropdown .dropbtn {
    display: flex;
    align-items: center;
    @include customize(font) {
      height: getValue(headerHeigth);
    }
    float: left;
    @include customize(theme) {
      color: getValue(color-c0);
    }
    text-align: center;
    padding: 0px 16px;
    text-decoration: none;
  }

  .dropdown {
    float: left;
    overflow: hidden;
    display: inline-block;
  }

  .dropdown .dropbtn {
    border: none;
    outline: none;
    @include customize(theme) {
      color: getValue(color-c0);
    }
    padding: 0px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }

  .dropbtn img {
    padding-left: 10px;
    padding-top: 5px;
    width: 10px;
    height: 10px;
  }

  a:hover, .dropdown:hover .dropbtn {
    span {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -moz-transform: scale(1.1);
    }
  }

  .dropItem:hover {
    @include customize(theme) {
      background-color: getValue(color-c20);
    }
  }


  /* Dropdown content (hidden by default) */
  .dropdown-content {
    display: none;
    position: absolute;
    @include customize(theme) {
      background-color: getValue(color-c21);
    }
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    text-align: left;
  }

  /* Add a grey background color to dropdown links on hover */
  .dropdown-content a:hover {
    //border-bottom: 1px solid red;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
    @include customize(font) {
      top: getValue(headerHeigthMT);
    }
  }
}
.hide {
  display: none;
}
.mobile-ham {
  height: 100%;
  text-decoration: none;
  @include customize(theme) {
    color: getValue(color-c0);
  }
  @include noSelect();
  i {
    width: 50px;
    height: 50px;
    font-size: 35px;
    margin-top: 25px;
    @include noSelect()
  }
}
.mobile-dropdown-content {
  &.hide {
    display: none;
  }
  width: 100%;
  min-height: 100%;
  @include customize(theme) {
    background-color: getValue(color-c3);
  }
  @include customize(font) {
    padding-top: getValue(headerHeigth);
  }
  a {
    padding: 15px 0px 10px 0px;
    margin-left: 10px;
    display: block;
    z-index: 10000;
    background-color: white;
    text-decoration: none;
    @include customize(font) {
      font-size: getValue(fontSize22);
    }
    @include customize(theme) {
      color: getValue(color-c0);
    }

  }
  padding-left: 0px;
}
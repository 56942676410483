@import "scss/globals.scss";
@import "scss/common.scss";

.App {
  height: 100%;
}

.App-container {
  @include customize(theme) {
    background-color: getValue(backgroundColor1);
    color: getValue(color-c0);
  }
  @include customize(font) {
    font-size: getValue(fontSize);
    h1 {
      font-size: getValue(h1);
    }
    h2 {
      font-size: getValue(h2);
    }
    h3 {
      font-size: getValue(h3);
    }
  }
  min-height:100%;
  position: relative;
}

.content {
  @include customize(font) {
    padding-top: getValue(headerHeigth);
    padding-bottom: getValue(footerHeight);
  }

}


.pageContent {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  .title {
    padding-top: 15px;
    text-align: center;
    margin: 0;
  }
  h1 {
    margin: 0;
  }
}

.compress {
  width: 60%;

  @media (max-width: 1000px) {
    width: 80%;
  }
}

.compress-more {
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (min-width: 961px) and (max-width: 1260px) {
    width: 80%;
  }
}
@import "../scss/globals.scss";

.articles {
  display: flex;
  @include customize(theme) {
    background-color: getValue(color-c26);
  }



  flex-direction: column;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;

  h1 {
    margin: 0;
  }

  .title {
    padding: 20px;
    //width: 100%;
    text-align: center;
    //background-color: white;
    font-weight: bolder;
    @include enlarge(1.3)
  }

  .list {
    width: 90%;
  }
}
.generatedContent {
  width: 100%;
  .columns {
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: center;

    .col {
      padding-left: 30px;
      padding-right: 30px;
      width: 220px;
    }
  }
    &.hidden {
      display: none;
    }
  .bullets {
    padding-left: 30px;
  }
  .paragraph {
    padding-top: 10px;
  }

  h3 {
      padding-top: 15px;
      text-align: center;
  }
}
.editDocs {
  //display: flex;
  //justify-content: center;
  max-width: 900px;

  .section {
    padding: 30px;

  }

  button {
    font-size: 16px;
    padding: 3px;
    margin-right: 20px;
    margin-top: 3px;
  }

  input {
    font-size: 14px;
  }

  .doc {
    padding: 10px;
    &:nth-of-type(2n) {
      background-color: lightgray;
    }
    &:nth-of-type(2n+1) {
      background-color: lightblue;
    }
  }
}
@import "../scss/globals.scss";

.adminPanel {
  .admin_head {
    background-color: lightgray;
    float:right;
    button {
      font-size: 24px;
      padding: 10px;
      margin: 10px;
    }
  }
  .tabsPanel {
    padding-bottom: 15px;
    button {
      border-radius: 0;
      font-size: 20px;
      padding: 15px;
      &.active {
        border-bottom: 5px blue solid;
      }
    }
  }
}

.adminPanelLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  table tr {
    height: 40px;
  }
  .admin_head {
    background-color: lightgray;
    float:right;
    button {
      font-size: 24px;
      padding: 10px;
      margin: 10px;
    }
  }
  input {
    font-size: 20px;
  }
  button {
    padding: 10px;
    margin-top: 10px;
    font-size: 20px;
  }
  .tabsPanel {
    padding-bottom: 15px;
    button {
      border-radius: 0;
      font-size: 20px;
      padding: 15px;
      &.active {
        border-bottom: 5px blue solid;
      }
    }
  }
}
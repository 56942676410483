@import "../scss/globals.scss";

.swiperContainer {
  width: 100%;
  display: flex;
  @include customize(theme) {
    background-color: getValue(swiperBgColor);
  }
}
.newsItem {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
  @include customize(font) {
    font-size: getValue(fontSize20);
  }
  a {
    text-decoration: none;
    @include customize(theme) {
      color: getValue(color-c0);
    }
  }
  h3 {
    @include customize(font) {
      font-size: getValue(fontSize26) !important;
    }
  }
  .date {
    @include customize(font) {
      font-size: getValue(fontSize);
    }
    float: right;
    margin-top: 20px;
    position: absolute;
    bottom: 30px;
    right: 0;
  }
  .newsMainPage {
    margin-bottom: 40px;
    align-self: center;
    i {
      padding-right: 10px;
    }
  }
}
.av_title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  width: 90%;
  text-align: center;
  justify-self: center;
  align-self: center;
  @media (max-width : 900px) {
    padding-top: 20px;
  }
  img {
    width: 100%;
    max-width: 1400px;
  }
  font-weight: bold;
  @include customize(font) {
    font-size: getValue(fontSize26) !important;
  }
}
.landingPage {
  display: flex;
  flex-direction: column;
}
.mySwiper {
    width: 50%;
    margin-bottom: 20px;

    @media (max-width: 900px) {
      width: 80%;
    }
}
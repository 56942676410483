@import "../scss/globals.scss";

.thanksPage {
  padding-top: 20px;

  #article_thanks {
    z-index: 100;
    @include customize(font) {
      font-size: getValue(fontSize20);
    }
  }
}
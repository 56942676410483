.teachers {
  &_section {
    padding-bottom: 20px;
    @media (max-width: 960px) {
      width: 100%;
    }
  }
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (max-width: 960px) {
    width: 100%;
  }
  margin: auto;
  align-items: center;
  align-content: center;
  .title {
    padding-top: 15px;
    text-align: center;
    margin: 0;
  }
  h1 {
    margin: 0;
  }
  .teachers_row {
    display: flex;
    padding-bottom: 15px;
    width: 100%;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 960px) {
      flex-direction: column;
    }
  }
  .teachers_col {
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    min-width: 450px;
    @media (max-width: 960px) {
      width: 100%;
      min-width: 320px;
      padding: 10px 0;
    }
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    img {
      padding: 0px 20px;
      width: 150px;
      height: 150px;
    }
    &.col-left {
      @media (max-width: 960px) {
       align-self: start;
        justify-content: start;
        justify-items: start;
        align-content: start;
        align-items: start;
      }
    }
    &.col-right {
      @media (max-width: 960px) {
       align-self: end;
        justify-content: flex-end;
        justify-items: flex-end;
        align-content: flex-end;
        align-items: flex-end;
      }
    }
  }
  .teachers_display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }
}
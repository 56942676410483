.editParents {
  padding: 20px;
  div:has(a) {
    margin-top: 10px;
  }

  button {
    font-size: 1rem;
    padding: 0.6rem;
    margin: 0.4rem;
  }

  input {
    font-size: 1rem;
    padding: 0.3rem;
  }

  .bottomButtons {
    margin-top: 1.5rem;
  }
}
@import "../scss/globals.scss";

.footer {
  @include customize(theme) {
    border-top: 2px solid getValue(color-c0);
  }

  display: flex;
  flex-direction: column;
  position:absolute;
  bottom:0;
  width:100%;
  @include customize(font) {
    height: getValue(footerHeight);
  }

  .footer-content {
    display: flex;
    min-height: 70%;
    width:100%;

    .localisation, .phone, .email, .contrast {
      margin-left: auto;
      display: flex;
      align-items: center;
      padding: 10px;
      @include customize(theme) {
        color: getValue(color-c0);
      }
      i {
        font-size: 24px;
        width: 20px;
        padding-right: 10px;
        &.email_img {
          width: 25px;
        }
      }

      a {
        text-decoration: none;
        @include customize(theme) {
          color: getValue(color-c0);
        }
        &:hover {
          @include enlarge(1.1);
          padding-left: 5px;
        }
      }
    }
    .phone {
      @media (max-width: 900px) {
        margin-left: 20px;
      }
    }
  }

  .footer-author {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 3px;
    .author {
      padding-left: 5px;
      text-decoration: none;
      @include customize(theme) {
        color: getValue(color-c7);
      }
    }
    @include customize(font) {
      font-size: getValue(fontAuthor);
    }
    @include customize(theme) {
      background-color: getValue(color-c22);
    }
  }

  .rightFooter {
    align-items: center;
    padding: 0px 10px;

    .bip {
      img {
        width: 45px;
        height: 45px;
        padding-right: 15px;
      }
    }

    a.fontChanger {
      display: block;
      padding-right: 5px;
      .contrast {
        display: none;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    a.highContrast {
      display: block;
      padding-left: 10px;
      padding-right: 5px;

      img {
        width: 20px;
        height: 20px;
      }
    }
    a:hover {
      cursor: pointer;
      @include enlarge(1.3);
    }
  }
}
.editActivities {
  display: flex;
  flex-direction: column;
  align-items: center;

  .actionBtn {
    font-size: 20px;
    padding: 10px;
  }
}

.activitiesList {
  padding: 20px;
  font-size: 1.4rem;
  margin: 1rem;
  div {
    margin: 0.6rem;
  }
}
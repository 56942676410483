@import "../scss/globals.scss";
@mixin fillColor($color) {
  .hand {
    @include customize(theme) {
      fill: getValue($color);
    }
  }
}
.hand {
  width: 60px;
  height: 60px;
  position: absolute;

  &.hide {
    visibility: hidden;
  }

  &.transform {
    transform: scaleY(-1, -1);

  }
}

svg:nth-of-type(10n-1) {
  @include fillColor(color-c6)
}

svg:nth-of-type(10n-2) {
  @include fillColor(color-c7)
}

svg:nth-of-type(10n-3) {
  @include fillColor(color-c8)
}

svg:nth-of-type(10n-4) {
  @include fillColor(color-c9)
}

svg:nth-of-type(10n-5) {
  @include fillColor(color-c10)
}

svg:nth-of-type(10n-6) {
  @include fillColor(color-c11)
}

svg:nth-of-type(10n-7) {
  @include fillColor(color-c12)
}

svg:nth-of-type(10n-8) {
  @include fillColor(color-c0)
}

svg:nth-of-type(10n-9) {
  @include fillColor(color-c13)
}
@import "../scss/globals.scss";

.archive {
  .item {
    @include customize(font) {
      font-size: getValue(fontSize22);
    }
    padding: 10px;
  }
  .showAllYears {
    margin-left: auto;
    span {
      @include customize(theme) {
        color: getValue(color-c1);
      }
      @include customize(font) {
        font-size: getValue(fontSize28);
      }
      padding: 10px;
    }
  }
  .showAllYears {
    margin: auto;
    background: none;
    border: none;
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    @include customize(font) {
      font-size: getValue(fontSize20);
    }
    @media (max-width: 800px) {
      @include customize(font) {
        font-size: getValue(fontSize18);
      }
    }
    &:hover {
      @include enlarge(1.2);
    }
    @media (max-width: 800px) {
      span {
        // display: none;
        //text-decoration: none;
        padding: 0;
      }
      padding-bottom: 20px;
      // text-decoration: underline;
      &:hover {
        @include enlarge(1)
      }
    }
    span {
      @media (max-width: 800px) {
        padding: 0 0 20px 0;
        text-decoration: none;
      }
    }
  }
}
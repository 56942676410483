@import "../scss/globals.scss";

.document {
  display: flex;
  @include customize(font) {
    font-weight: getValue(fontSize18);
  }
  flex-direction: row;
  // border-bottom: 1px dashed gray;
  padding: 25px 10px;

  &:last-child {
    //border-bottom: none;
  }

  @include customize(theme) {
    background-color: getValue(documentsRow1);
  }

  &:nth-child(2n) {
    @include customize(theme) {
      background-color: getValue(documentsRow2);
    }
  }

  &:first-child {
    @media (min-width: 800px) {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  &:last-child {
    @media (min-width: 800px) {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  a {
    margin-left: auto;

    &:hover {
      @include enlarge(1.2);
    }
  }
}
.text-with-link {
  padding: 15px;
}

.bold {
  font-weight: bold;
}

.separate-top {
  @include customize(theme) {
    border-top: 1px dashed getValue(color-c23);
  }
}

.center {
  text-align: center;
  justify-items: center;
}

.vertical-space-20 {
  margin-right: 20px;
}

.spacing-bottom {
  margin-bottom: 20px;
}

.justify {
  text-align: justify;
}

.spacing-bottom-big {
  margin-bottom: 40px;
}

.spacing-top {
  padding-top: 15px;
}

.spacing-top-40 {
  margin-top: 40px;
}

.spacing-left {
  padding-left: 15px;
}

.less-spacing-top {
  margin-top: 0px;
}

.no-top-spacing {
  margin-top: 0 !important;
  padding-top: 0 !important;;
}

.lower-spacing {
  margin-top: 5px;
  margin-bottom: 5px;
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

h3 {
  padding-top: 15px;
  text-align: center;
}
.galeryPreview {
  .items {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      padding: 10px;
      button {
        margin-left: 20px;
        max-height: 50px;
      }
    }
  }
  img {
    margin: 10px;
    max-width: 100px;
    max-height: 100px;
    &:hover {
      max-width: 400px;
      max-height: 400px;
    }
  }
}
@import "../scss/globals.scss";

.news {
  display: flex;
  @include customize(theme) {
    //background-color: getValue(color-c24);
  }
  width: 100%;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-bottom: 30px;

  .pagetitle {
    padding: 30px;
    @include customize(font) {
      font-size: getValue(fontSize26);
    }
  }

  .one-news {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    align-self: center;
    padding: 10px;
    width: 600px;
    .generatedContent h3 {
      padding-top: 0px;
      margin-top: 0px;
    }
    @media(max-width: 1200px) {
      width: 92%;
      margin-left: 10px;
      margin-right: 10px;
    }
    @media(min-width: 1200px) and (max-width: 1500px){
      width: 45%;
      margin-left: 15px;
      margin-right: 15px;
    }
    @include customize(theme) {
      background-color: getValue(color-c25);
    }
    @include customize(font) {
      font-size: getValue(fontSize18);
    }

    img {
      max-width: 540px;

    }
  }

  .one-news:nth-of-type(4n+3) {
    @include customize(theme) {
      background-color: getValue(color-c27);
    }
  }

  .one-news:nth-of-type(4n+1) {
    @include customize(theme) {
      background-color: getValue(color-c28);
    }
  }

  .one-news:nth-of-type(4n+2) {
    @include customize(theme) {
      background-color: getValue(color-c29);
    }
  }

  .pin {
    width: 50px;
    margin-bottom: -60px;
    position: relative;
    bottom: 40px;
    &.pin-right {
      left: 500px;
      @media(max-width: 800px) {
        left: 75%;
      }
      @media(min-width: 801px) and (max-width: 1200px){
        left: 85%;
      }
      @media(min-width: 1201px) and (max-width: 1500px){
        left: 85%;
      }
    }
    &.pin-left {
      @media(max-width: 1200px) {
        right: 20px;
      }
      @media(min-width: 1200px) and (max-width: 1500px){
        right: 20px;
      }
    }
  }

  .board {
    background-image: url('./board.png');
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1400px;
    padding: 30px;
    padding-top: 10px;
    margin-top: 20px;
    border-image: url('./board2.png') 30% repeat;
    border-style: solid;
    border-width: 20px;

    .newsImg {
      width: 90%;
    }

    @media(max-width: 860px) {
      width: 75%;
    }
    @media(min-width: 860px) and (max-width: 1200px) {
      width: 85%;
      padding: 25px;
    }
    @media(min-width: 1200px) and (max-width: 1500px){
      width: 90%;
      padding: 20px;
    }
  }


  .date {
    text-align: center;
    padding-bottom: 10px;
    margin-top: 20px;
  }

  .showALl {
    padding-top: 30px;
  }

}
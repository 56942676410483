@import "../scss/globals.scss";

.actColumns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  .handsLeft, .handsRight {
    min-width: 14%;
  }

  .handsRight {
    min-width: 14%;
  }
}
.activities {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 70%;
  min-width: 70%;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    width: 100%;
  }
  .title {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    font-weight: bold;
    @include customize(font) {
      font-size: getValue(fontSize28);
    }
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .date {
    margin-top: 10px;
    @include customize(font) {
      font-size: getValue(fontSize);
    }
  }

  h1.title {
    padding-top: 0;
  }

  .navigation {
    display: flex;
    width: 90%;
    @media (max-width: 800px) {
      width: 100%;
      @include customize(font) {
        font-size: getValue(fontSize);
      }
    }
    flex-direction: row;

    button {
      background: none;
      border: none;
      display: flex;
      align-content: center;
      justify-content: center;
      justify-items: center;
      align-items: center;
      cursor: pointer;
      @include customize(theme) {
        color: getValue(color-c0);
      }
      @include noSelect();
      &.hide {
        visibility: hidden;
      }
      span {
        @include customize(font) {
          font-size: getValue(fontSize20);
        }
        @media (max-width: 800px) {
          @include customize(font) {
            font-size: getValue(fontSize18);
          }
        }
      }
      &.nextButton {
        img {
          -webkit-transform:rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        @media (max-width: 800px) {
            width: 60px;
            padding-bottom: 20px;
        }
      }
    }

    .previousButton {
      @media (max-width: 800px) {
        width: 60px;
        padding-bottom: 20px;
      }
      margin-left: auto;
      padding-left: 30px;
      &:hover {
        @include enlarge(1.2)
      }
      @media (max-width: 800px) {
        padding-left: 10px;
        &:hover {
          @include enlarge(1);
        }
      }
    }

    .showAll {
      margin-left: auto;
      span {
        @include customize(theme) {
          color: getValue(color-c1);
        }
        @include customize(font) {
          font-size: getValue(fontSize28);
        }
        padding: 10px;
      }
    }

    .nextButton {
      &:hover {
        @include enlarge(1.2);
      }
      @media (max-width: 800px) {
        &:hover {
          @include enlarge(1);

        }
      }
    }
  }

  .showAllBottom {
    margin-top: 30px;
  }
  .showAll {
    background: none;
    border: none;
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    @include customize(font) {
      font-size: getValue(fontSize20);
    }
    @media (max-width: 800px) {
      @include customize(font) {
        font-size: getValue(fontSize18);
      }
    }
    &:hover {
      @include enlarge(1.2);
    }
    @media (max-width: 800px) {
       span {
        // display: none;
         //text-decoration: none;
         padding: 0;
       }
      padding-bottom: 20px;
     // text-decoration: underline;
      &:hover {
        @include enlarge(1)
      }
    }
    span {
      @media (max-width: 800px) {
        padding: 0 0 20px 0;
        text-decoration: none;
      }
    }
  }

  .activities-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    @media (max-width: 800px) {
      width: 86%;
    }
  }

  &.activities_list {
    .title {
      width: 100%;
      @media (max-width: 800px) {
        margin-left: 30px;
      }
    }
    .title:after {
      @include customize(theme) {
        border-bottom: 2px solid getValue(color-c26);
      }
      width: 200px;
      margin-left: 20px;
      left: 0;
      position: relative;
      top: 20%;
      z-index: 0;
      content: '';
      @media (max-width: 800px) {
       border: none;
      }
    }

    .list {
      @media (max-width: 800px) {
        min-width: unset;
        margin-left: 10px;
        margin-right: 10px;
      }
      min-width: 700px;
      margin-left: 200px;
    }

    .listItem {
      @include customize(theme) {
        border-left: 6px solid getValue(color-c1);
      }
      margin-bottom: 15px;
      @include customize(font) {
        font-size: getValue(fontSize20);
      }
      @media (max-width: 800px) {
        padding: 5px 15px;
      }
      padding: 5px 25px;
      display: flex;

      &:hover {
        @include customize(theme) {
          background-color: getValue(color-c2);
        }
      }

      a {
        line-height: 26px;
        &:hover {
          @include customize(font) {
            font-size: getValue(fontSize22);
          }
          @media (max-width: 800px) {
            @include customize(font) {
              font-size: getValue(fontSize20);
            }
          }
        }
        text-decoration: none;
        padding-bottom: 5px;
        @include customize(theme) {
          color: getValue(color-c0);
        }
      }
    }
  }
}

.addContent {
  .fieldItem {
    margin: 1rem;
    border: 0.3rem solid gray;
    max-width: 80rem;
    padding: 0.3rem;
    border-radius: 0.8rem;
    background-color: #dfdfdf;
  }
  textarea {
    font-size: 1.1rem;
  }
}
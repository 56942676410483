@import "../scss/globals.scss";

.groups {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
  align-content: center;

  img {
    width: 70px;
    padding-right: 20px;
  }

  .groupName {
    @include customize(font) {
      font-size: getValue(fontSizeBigger);
    }
  }

  .name {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding-top: 20px;
  }
}

